/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import 'typeface-work-sans';
import 'typeface-raleway';
import 'typeface-montserrat';
import '../styles/main.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        logo : file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality: 92) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <main className="main">
          {children}
        </main>
      </>
    )}
  />
)

export default Layout
